<template>
  <div class="container">
    <vueper-slides
      v-if="realisations"
      ref="mySlide"
      class="no-shadow"
      arrows-outside
      infinite
      :touchable="true"
      :visible-slides="3"
      :breakpoints="{ 1200: { visibleSlides: 2, slideMultiple: 2} , 700: {visibleSlides: 1, slideMultiple: 1}}"
      :gap="1"
      :bullets="false"
      :dragging-distance="70"
      transition-speed='250'
      fixed-height="52vh"
      @next="setCorrectNextSlide($event)"
      @previous="setCorrecPreviousSlide($event)"
    >

      <template #arrow-left>
        <img class="arrowleft" src="../../assets/icons/arrowLeft.svg" alt="flèche gauche">
      </template>
      <template #arrow-right>
        <img class="arrowright" src="../../assets/icons/arrowRight.svg"
             alt="flèche droite">
      </template>

      <vueper-slide v-for="real in realisations"
                    :key="real.image.id">
        <template #content>
          <img class="vueperimg"
               :src="real.image.url"
               :srcset="createSrcSet(real.image.sizes)"
               :alt="real.image.alt"
               sizes="(max-width: 700px) 75vw, (max-width: 1000px) 45vw, 33vw"
          draggable="false">
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import allMixins from '../../assets/mixins/allMixins'

export default {
  name: 'vueper',
  components: {
    VueperSlides,
    VueperSlide
  },
  mixins: [allMixins],
  props: {
    realisations: Object,
  },
  data () {
    return {
      //This is used to know how many slides are visible
      //for the methods setCorrectNextSlide & setCorrectPreviousSlide
      visibleSlides: window.innerWidth > 1100 ? 3 :
        window.innerWidth > 700 ? 2 : 1,
    }
  },
  methods: {
    setCorrectNextSlide (event) {
      //Slider behaves in strange way when there are 2 slides showing.
      if(this.visibleSlides === 2) {
        return;
      }
      //If no slide is set
      if (!event.currentSlide) {
        //Move directly to the second picture
        this.$refs.mySlide.goToSlide(1)
      }
      //If we have no more further pictures to show on the right
      else if (event.currentSlide.index ===
        this.realisations.length - (this.visibleSlides - 1)) {
        //move directly to the first picture
        this.$refs.mySlide.goToSlide(0)
      }
    },
    setCorrecPreviousSlide (event) {
      if (!event.currentSlide || event.currentSlide.index < (this.visibleSlides - 1)) {
        this.$refs.mySlide.goToSlide(this.realisations.length - 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  grid-column: col-start 1 / span 12;
  margin-top: 10vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.arrowleft {
  position: relative;
  left: -4rem;
}

.arrowright {
  position: relative;
  right: -4rem;
}

.vueperimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media(max-width: $xs-screen) {
  .container {
    grid-column: col-start 2 / span 10;
  }

  .arrowleft {
    left: -3rem;
  }

  .arrowright {
    right: -3rem;
  }
}

</style>
