<template>
  <div class="form">
    <div class="form-flex">
      <label for="nom">{{ sitedata.formulaire_nom }}</label>
      <input :class="{inputerror : errors.nameError}"
             type="text" v-model="form.name" id="nom">
      <p class="error-label" :class="{visibleError : errors.nameError}">
        {{ sitedata.formulaire_erreur_nom }}</p>

      <label for="email">{{ sitedata.formulaire_email }}</label>
      <input :class="{inputerror : errors.emailError}"
             type="text" v-model="form.email" id="email">
      <p class="error-label" :class="{visibleError : errors.emailError}">
        {{ sitedata.formulaire_erreur_email }}</p>

      <label for="sujet">{{ sitedata.formulaire_sujet }}</label>
      <div id="sujetcontainer">
      <select
        v-if="sitedata.liste_sujets"
        v-model="form.sujet" id="sujet">
        <option
          v-for="(sujet,index) in sitedata.liste_sujets"
        >
          {{ sujet.titre }}
        </option>
      </select>
      </div>
      <label for="message" class="form-message">{{ sitedata.formulaire_message }}</label>
      <textarea rows="6" v-model="form.message" id="message"/>
      <v-button :class="{sending : status.sending}"
                :text="sitedata.formulaire_boutton_envois"
                :disabled="sendButtonDisabled"
                @click="verifyForm"/>
      <div class="results" :class="{hidden : !status.showSendResult}">
        <p class="failure" :class="{hidden : !errors.sendError}">
          {{ sitedata.formulaire_envois_erreur }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '../atoms/vButton'

export default {
  name: 'vForm',
  components: { VButton },
  props: {
    sitedata: Object
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        sujet: '',
        message: '',
      },
      errors: {
        nameError: false,
        emailError: false,
        sendError: false,
      },
      status: {
        sending: false,
        showSendResult: false,
      },
      sendButtonDisabled: false,
    }
  },
  watch: {
    sitedata () {
      if (this.sitedata.liste_sujets[0]) {
        this.form.sujet = this.sitedata.liste_sujets[0].titre
      }
    }
  },
  methods: {
    verifyForm () {
      this.errors.emailError = !this.emailIsOk(this.form.email)
      this.errors.nameError = !this.nameIsOk(this.form.name)

      if (!this.errors.emailError && !this.errors.nameError) {
        this.sendForm()
      }
    },
    nameIsOk (enteredName) {
      return enteredName.length > 0
    },
    emailIsOk (enteredEmail) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return enteredEmail.length > 0 && emailRegex.test(enteredEmail)
    },
    async sendForm () {
      this.status.sending = true
      this.status.showSendResult = false

      const response = await fetch(`${this.fetchlink}/wp-json/v1/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.form),
      })

      this.errors.sendError = !response.ok
      this.status.sending = false

      if(!this.errors.sendError) {
        this.sitedata.formulaire_boutton_envois = this.sitedata.formulaire_envois_ok;
        this.sendButtonDisabled = true;
      }
      this.status.showSendResult = true;

    }
  }
}
</script>

<style scoped lang="scss">
.form {
  grid-column: col-start 6 / span 6;
  grid-row: 1 / span 2;
  margin-top: 10vh;
  margin-bottom: 17vh;
  background-color: $LightGreen;
  padding: 8vh 5vw;

  .form-flex {
    display: flex;
    flex-direction: column;
  }

  .error-label {
    color: red;
    visibility: hidden;
  }

  .visibleError {
    visibility: visible !important;
  }

  label {
    color: $DarkGreen;
    font-weight: 400;
  }

  input, select, textarea {
    margin-top: 0.5rem;
    border: none;
    padding: 1.5rem 2rem;
    border-radius: 5px;
    resize: none;
  }

  #sujetcontainer {
    position: relative;
    select {
      appearance: none;
      position: relative;
      width: 100%;
    }
    &:after {
      content: ">";
      transform: rotate(90deg);
      font-size: 18px;
      position: absolute;
      right: 2rem;
      top: 2.5rem;
      pointer-events: none;
    }
  }

  .inputerror {
    border: 1px solid red;
  }

  .form-message {
    margin-top: 2.5rem;
  }

  button {
    align-self: flex-end;
    margin-top: 5vh;
  }

  .sending {
    animation: backgroundlive 0.7s infinite;
  }

  @keyframes backgroundlive {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }

  .hidden {
    visibility: hidden;
  }

  .results {
    position: relative;
    margin-top: 1rem;

    * {
      position: absolute;
      top: 0;
      text-align: end;
    }

    .failure {
      color: red;
    }
  }
}

@media (max-width: $small-screen) {
  .form {
    grid-column: col-start 6 / span 7;
  }

  #sujetcontainer {
    &:after {
      top: 2rem !important;
    }
  }
}

@media (max-width: $xs-screen) {
  .form {
    grid-column: col-start 1 / span 12;
    grid-row: 2;
    margin-top: -7vh;
    padding: 4vh 5vw;
    margin-bottom: 7vh;
  }
}



</style>
