import { createApp } from 'vue'
import Vue from "vue";
import App from './App.vue'
import VueSplide from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import VueGtag from "vue-gtag";

const app = createApp(App)

app.config.globalProperties.fetchlink = 'https://verdonpaysagisme.ch/wp'
// app.config.globalProperties.fetchlink = "https://verdon.raccoon.ch/wp";

app.use(VueSplide).use(VueGtag, {  config: { id: 'G-C5YZ78B56P' }}).mount('#app')
