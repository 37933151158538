<template>
  <div class="container">
    <div class="dot">
      <img :src="data.logo.url" :alt="data.logo.alt">
    </div>
    <p class="titre"> {{ data.titre_etape }}</p>
    <p> {{ data.description_etape }}</p>
  </div>
</template>

<script>
export default {
  name: 'Etape',
  props: {
    data: Object,
  }
}
</script>

<style scoped lang="scss">

.container {
  flex-basis: 25%;

  margin-left: 1.5rem;
  margin-right: 1.5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  padding: 3rem 3rem;
  padding-bottom: 3vw;

  border: 1px solid $LightGreen;
  border-radius: 13px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .dot{
    background-color: $LightGreen;
    border-radius: 50%;
    padding: 2rem;
    width: 8rem;
    height: 8rem;

    img {
      width: 4rem;
      height: 4rem;
    }
  }

  .titre {
    font-weight: bold;
    margin-top: 1.5vw;
  }

  transition: box-shadow .7s, transform .7s;

}


@media (max-width: $small-screen) {
  .container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 3rem 1.5rem;
  }
}

@media (max-width: $xs-screen) {
  .container {
    flex-basis: 45%;
    padding: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }

    .dot{
      width: 5rem;
      height: 5rem;
      padding: 1rem;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

</style>
