<template>
  <div class="intro">
    <div class="background-effect"/>
    <div class="intro-flex slide-in-right" v-if="data.titre_principal">
      <h1>{{ data.titre_principal }}</h1>
      <p>{{ data.description_principale }}</p>
      <v-button
        :text="data.boutton_daction"
        @click="scrollto('contact')"/>
    </div>
    <img class="intro-img"
         v-if="data.image_principale"
         :src="data.image_principale.url"
         :srcset="createSrcSet(data.image_principale.sizes)"
         :alt="data.image_principale.alt"
         sizes="(max-width: 700) 72%, (max-width: 1100) 50%, 38%"
    />
  </div>

  <div class="jardin">
    <div class="back-color"/>
    <div class="jardin-flex">
      <h2>{{ data.titre_jardin }}</h2>
      <hr class="line">
      <p> {{ data.description_jardin }}</p>
    </div>
    <div class="jardin-img">
      <img
        v-if="data.image_jardin"
        :src="data.image_jardin.url"
        :srcset="createSrcSet(data.image_jardin.sizes)"
        :alt="data.image_jardin.alt"
        sizes="(max-width: 700) 86%%, (max-width: 1100) 43%, 36%%"/>
    </div>
  </div>

  <div class="processus">
    <div class="processus-titre-flex">
      <h2>{{ data.titre_processus }}</h2>
      <hr class="line">
    </div>
    <p class="processus-description"> {{ data.description_processus }}</p>
    <div class="etapes-flex">
      <Etape
        class="etape"
        v-for="etape in data.etapes_processus"
        :key="etape.titre_etape"
        :data="etape"/>
    </div>
  </div>

  <div class="jardin-2">
    <img class="jardin-img-2"
         v-if="data.image_jardin_2"
         :src="data.image_jardin_2.url"
         :srcset="createSrcSet(data.image_jardin_2.sizes)"
         :alt="data.image_jardin_2.alt"
         sizes="(max-width: 700) 86%, 50%%"/>
    <h2 class="description-jardin-2 specialtext">{{ data.description_jardin_2 }}</h2>
  </div>

  <div class="realisations">
    <div class="realisation-flex">
      <h2 class="titre-realisations">{{ data.titre_realisations }}</h2>
      <hr class="line" align="center"/>
    </div>
    <h2 class="description-realisations">{{ data.description_realisations }}</h2>
    <Splider :realisations="data.realisations" class="splider"/>
  </div>

  <div class="contact">
    <div class="back-color-2"/>
    <div class="contact-flex">
      <h2>{{ data.titre_projet }}</h2>
      <hr class="line">
      <p>{{ data.description_projet }}</p>
    </div>
    <v-form v-if="data" :sitedata="formData"/>
  </div>

</template>

<script>
import VButton from '../components/atoms/vButton'
import Etape from '../components/molecules/Etape'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import allMixins from '../assets/mixins/allMixins'
import VForm from '../components/molecules/vForm'
import Vueper from '../components/molecules/vueper'
import Splider from '../components/molecules/Splider'

export default {
  name: 'MainPage',
  components: {
    Splider,
    Vueper,
    VForm,
    Etape,
    VButton,
    Splide,
    SplideSlide,
  },
  mixins: [allMixins],
  props: {
    data: Object
  },
  mounted () {
  },
  data () {
    return {
      appearAnimations: {
        'jardin-flex': 'slide-in-left',
        'processus-description': 'slide-in-right',
        'description-jardin-2': 'slide-in-right',
        'description-realisations': 'slide-in-left',
        'contact-flex': 'slide-in-left'
      }
    }
  },
  computed: {
    formData () {
      return {
        formulaire_nom: this.data.formulaire_nom,
        formulaire_erreur_nom: this.data.formulaire_erreur_nom,
        formulaire_email: this.data.formulaire_email,
        formulaire_erreur_email: this.data.formulaire_erreur_email,
        formulaire_sujet: this.data.formulaire_sujet,
        liste_sujets: this.data.liste_sujets,
        formulaire_message: this.data.formulaire_message,
        formulaire_boutton_envois: this.data.formulaire_boutton_envois,
        formulaire_envois_ok: this.data.formulaire_envois_ok,
        formulaire_envois_erreur: this.data.formulaire_envois_erreur,
      }
    }
  }
}
</script>

<style scoped lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

$intro-margin: 10vh;

.intro {
  @include twelve-col-grid;
  margin-top: $intro-margin;


  .background-effect {
    grid-column: col-start 6 / span 7;
    grid-row: 1;
    z-index: -1;
    background-color: $LightGreen;
    margin-right: $side-align;
    position: relative;
    top: calc(-#{$intro-margin} - (2 * #{$header-margin}) - #{$header-img-height});
    height: 100vh;
  }

  .intro-flex {
    grid-column: col-start 1 / span 4;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15vh;
    align-items: flex-start;

    h1 {
      margin-right: 5rem;
      color: $DarkGreen;
    }

    p {
      margin-top: 2.6rem;
      margin-bottom: 5rem;
    }
  }

  .intro-img {
    grid-column: col-start 7 / span 5;
    grid-row: 1;
    height: 90vh;
    width: 100%;
    object-fit: cover;
  }
}

.jardin {
  @include twelve-col-grid;
  margin-top: 11vh;

  .back-color {
    grid-column: col-start 1 / span 8;
    grid-row: 1;
    background-color: $DarkGreen;
    margin-left: $side-align;
  }

  .jardin-flex {
    margin-top: 10vh;
    margin-bottom: 9vh;
    grid-column: col-start 2 / span 4;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
      color: white;
    }

    .line {
      margin-top: 2rem;
      margin-bottom: 2.5rem;
      width: 7rem;
      border-top: 3px solid white;
      margin-left: 0;
    }
  }

  .jardin-img {
    grid-column: col-start 7 / span 5;
    grid-row: 1;
    position: relative;
    top: 10vh;

    img {
      width: 100%;
      height: 40vh;
      object-fit: cover;
    }
  }
}

.processus {
  @include twelve-col-grid;
  margin-top: 30vh;

  .processus-titre-flex {
    grid-column: col-start 3 / span 2;
    display: flex;
    flex-direction: column;

    .line {
      margin-top: 2rem;
      width: 7rem;
      border-top: 3px solid $DarkGreen;
      margin-left: 0;
    }
  }

  .processus-description {
    grid-column: col-start 6 / span 5;
    white-space: pre-wrap;
  }

  .etapes-flex {
    margin-top: 15vh;
    grid-column: col-start 2 / span 10;
    display: flex;
    justify-content: space-between;
  }
}

.jardin-2 {
  @include twelve-col-grid;
  margin-top: 24vh;

  .jardin-img-2 {
    grid-column: col-start 1 / span 6;
    width: calc(100% + #{$side-padding});
    margin-left: $side-align;
    margin-top: auto;
    margin-bottom: auto;
    height: 90vh;
    object-fit: cover;
  }

  .description-jardin-2 {
    grid-column: col-start 8 / span 5;
    margin-top: auto;
    margin-bottom: auto;
    color: $DarkGreen;
  }
}

.realisations {
  @include twelve-col-grid;
  margin-top: 28vh;

  .realisation-flex {
    grid-column: col-start 1 / span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;


    .titre-realisations {
      grid-column: col-start 1 / span 12;
      text-align: center;
      color: $DarkGreen;
    }

    .line {
      margin-top: 2rem;
      border-top: 3px solid $DarkGreen;
      width: 7rem;
    }
  }

  .description-realisations {
    grid-column: col-start 3 / span 8;
    //No margin top as grid gutter above it
    text-align: center;
    white-space: pre-wrap;
  }

  .splider {
    margin-top: 10vh;
  }
}

.contact {
  @include twelve-col-grid;
  margin-top: 37vh;

  .back-color-2 {
    grid-column: col-start 1 / span 12;
    grid-row: 1;
    background-color: $DarkGreen;
    margin-left: $side-align;
    margin-right: $side-align;
  }

  .contact-flex {
    grid-column: col-start 2 / span 4;
    margin: 10vh 0;
    margin-right: 1rem;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    * {
      color: white;
    }

    .line {
      border-top: 3px solid white;
      width: 7rem;
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 2.5rem;
    }
  }
}

@media (max-width: $small-screen) {

  .intro {
    .background-effect {
      grid-column: col-start 6 / span 7;
    }

    .intro-flex {
      grid-column: col-start 1 / span 5;
    }

    .intro-img {
      grid-column: col-start 6 / span 7;
    }
  }

  .jardin {
    .back-color {
      grid-column: col-start 1 / span 8;
    }

    .jardin-flex {
      margin-top: 5vh;
      margin-bottom: 4vh;
      grid-column: col-start 2 / span 4;
    }

    .jardin-img {
      grid-column: col-start 7 / span 6;
    }
  }

  .processus {
    .processus-titre-flex {
      grid-column: col-start 2 / span 2;
    }

    .processus-description {
      grid-column: col-start 4 / span 8;
    }

    .etapes-flex {
      grid-column: col-start 1 / span 12;
    }
  }

  .realisations {
    .description-realisations {
      grid-column: col-start 2 / span 10;
    }

    .splider {
      margin-top: 10vh;
    }
  }
}

@media (max-width: $xs-screen) {
  .intro {
    background-color: $LightGreen;
    margin-top: 0;
    padding-top: $intro-margin;

    .background-effect {
      grid-column: col-start 1 / span 12;
      top: 0;
      margin-left: $side-align;
      height: auto;
    }

    .intro-img {
      grid-column: col-start 2 / span 10;
      height: 60vh;
      grid-row: 1;
    }

    .intro-flex {
      grid-column: col-start 2 / span 10;
      grid-row: 2;
      margin-top: 0;
      margin-bottom: 5vh;
    }


  }

  .jardin {
    .back-color {
      grid-column: col-start 1 / span 12;
      grid-row: 1 / span 2;
      margin-right: $side-align;
      margin-top: 25vh;
    }

    .jardin-flex {
      margin-top: 5vh;
      margin-bottom: 4vh;
      grid-column: col-start 2 / span 10;
      grid-row: 2;
    }

    .jardin-img {
      grid-column: col-start 1 / span 12;
      grid-row: 1;
      top: 0;

      img {
        height: 30vh;
      }
    }
  }

  .processus {
    margin-top: 10vh;

    .processus-titre-flex {
      grid-column: col-start 2 / span 6;
    }

    .processus-description {
      grid-column: col-start 2 / span 10;
      grid-row: 2;
    }

    .etapes-flex {
      margin-top: 5vh;
      flex-wrap: wrap;
    }
  }

  .jardin-2 {
    margin-top: 10vh;

    .jardin-img-2 {
      grid-column: col-start 1 / span 12;
      width: 100%;
      margin-left: 0;
      height: 70vh;
    }

    .description-jardin-2 {
      grid-column: col-start 1 / span 12;
      text-align: center;
    }
  }


  .realisations {
    margin-top: 10vh;

    .description-realisations {
      grid-column: col-start 1 / span 12;
    }

    .splider {
      margin-top: 2vh;
    }
  }

  .contact {
    margin-top: 10vh;

    .contact-flex {
      grid-column: col-start 1 / span 12;
      margin-top: 5vh;
      margin-bottom: 10vh;
      align-items: flex-start;

      .line {
        margin-left: 0;
      }

      p {
        text-align: start;
      }
    }
  }
}

</style>
