export default {
  methods: {
    createSrcSet (sizesObjetc) {
      const requiredSizes = [
        '2048x2048',
        '1536x1536',
        'large',
        'medium_large',
        'medium'
      ]
      let returnSrcSet = ''
      for (let i = 0; i < requiredSizes.length; i++) {
        const currentSize = requiredSizes[i]
        if (sizesObjetc[currentSize]) {
          const widthName = `${currentSize}-width`
          returnSrcSet += `${sizesObjetc[currentSize]} ${sizesObjetc[widthName]}w,`
        }
      }
      return returnSrcSet.length > 0 ? returnSrcSet.slice(0, -1) : ''
    },
    scrollto(slugLien) {
      const el = document.querySelector(`.${slugLien}`);
      el.scrollIntoView({behavior: "smooth"});
    }
  }
}
