<template>
  <div class="footer">
    <p>{{ footer_data.texte_footer }}</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    footer_data:Object,
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: $DarkGreen;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  color: white;
  text-align: center;
}
</style>
