<template>
  <div class="header">
    <img
      v-if="headerData.logo_verdon"
      :src="headerData.logo_verdon.url"
      :srcset="createSrcSet(headerData.logo_verdon.sizes)"
      :sizes="logoSizes"
      alt="logo Verdon"
      :style="{height : headerImgHeight}"
    >
    <div class="links">
      <a
        v-for="lien in headerData.liens"
        :key="lien.titre_lien"
        @click="scrollto(lien.slug_lien)"
      >
        {{lien.titre_lien}}
      </a>

    </div>
  </div>
  <hr class="line">
</template>

<script>
import allMixins from '../assets/mixins/allMixins'
export default {
  name: 'Header',
  props: {
    headerData: Object ,
  },
  mixins: [allMixins],
  methods: {
    scrollto(slugLien) {
      const el = document.querySelector(`.${slugLien}`);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  data() {return {
    headerImgHeight : 40,
  }
  },
  computed: {
    logoSizes: function() {
      if(this.headerData) {
        let imageRatio = this.headerData.logo_verdon.width / this.headerData.logo_verdon.height;
        return (imageRatio * this.headerImgHeight) + "px";
      } else {
        return "100px";
      }
    }
  }
}
</script>

<style scoped lang="scss">

.header{
  padding: 0 $side-padding;
  margin-top: $header-margin;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: $header-img-height;
    width: auto;
  }
}

a {
  margin: 0 2rem;
  padding: 1rem 0;
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    text-shadow: 1px 0 0 $DarkGreen;
  }
}

.line {
  margin-top: $header-margin;
  margin-bottom: 0;
}

@media (max-width: $small-screen) {
  a {
    margin: 0 1rem;
  }
}

@media (max-width: $xs-screen) {

  .header {
    flex-direction: column;
  }

  .links {
    margin-top: 3rem;
  }

}

</style>
