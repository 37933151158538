<template>
<button :disabled='disabled' :class="{disabled : disabled}">
  {{text}}
</button>
</template>

<script>
export default {
  name: 'vButton',
  props: {
    text: String,
    disabled: Boolean,
  }
}
</script>

<style scoped lang="scss">
button {
  background-color: $DarkGreen;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 2.5rem;

  transition: transform .7s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.disabled {
  background-color: grey !important;
  &:hover {
    cursor: default;
    transform: scale(1.0);
  }
}
</style>
