<template>
  <teleport to="head">
    <title>{{ pageData.meta_title }}</title>
    <meta name="description" :content="pageData.meta_description" />
  </teleport>
  <Header :header-data="headerData"/>
  <MainPage :data="pageData"/>
  <Footer :footer_data="this.footerData"/>
</template>

<script>

import Footer from './components/Footer'
import Header from './components/Header'
import MainPage from './views/MainPage'

export default {
  name: 'App',
  components: {
    MainPage,
    Header,
    Footer
  },
  created() {
    fetch(
      `${this.fetchlink}/wp-json/wp/v2/pages?slug=page-principale`
    )
      .then((r) => r.json())
      .then(r => {
        const data = r[0].acf;
        this.headerData = {
          logo_verdon: data.logo_verdon,
          liens: data.liens,
        };
        this.pageData = data;
        this.footerData = {
          texte_footer: data.texte_footer,
        };

        setTimeout(() => {

        },500)

      });
  },
  data() {
    return {
      headerData: [],
      pageData: [],
      footerData: [],
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/cssNormalize.css";
@import "assets/styles/fonts";
@import "assets/styles/basestyles";
@import "assets/styles/animations";
//colors & layout imported via vue.config.js (repeated in every component)
</style>
