<template>
  <div class="container">
    <splide
      v-if="realisations"
      :options="options"
    >
      <splide-slide v-for="real in realisations" :key="real.id">
        <img :src="real.image.url" :alt="real.image.alt"
             :srcset="createSrcSet(real.image.sizes)"
             sizes="(max-width: 700) 65%, (max-width: 1200) 61%, 42%"/>
        >
      </splide-slide>

      <template v-slot:controls>
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev left">
            <img src="../../assets/icons/arrowLeft.svg" alt="Flèche gauche">
          </button>
          <button class="splide__arrow splide__arrow--next right">
            <img src="../../assets/icons/arrowRight.svg" alt="Flèche droite">
          </button>
        </div>
      </template>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import allMixins from '../../assets/mixins/allMixins'
export default {
  name: 'Splider',
  mixins: [allMixins],
  components: {
    Splide,
    SplideSlide,
  },
  props: {
    realisations: Object,
  },
  data () {
    return {
      options: {
        rewind: true,
        focus: 'center',
        height: '60vh',
        type: 'loop',
        perPage: 1,
        padding : {
          right: '20vw',
          left: '20vw',
        },
        breakpoints: {
          1200: {
            padding: {
              right: '10vw',
              left: '10vw',
            }
          },
          700: {
            padding: {
              right: '0vw',
              left: '0vw',
            }
          }
        }
      },
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  grid-column: col-start 1 / span 12;
  margin: 0 2vw;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0 0.5rem;

  //removes scaling applied to all other images on site
  &:hover {
    transform: none;
  }
}
.splide__arrows {
  button {
    background-color: transparent;
    width: 5rem;
    height: 5rem;

    img {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  .left {
    position: absolute;
    left: -7rem;
  }

  .right {
    position: absolute;
    right: -7rem;
  }
}

@media(max-width: $xs-screen) {
  .container {
    grid-column: col-start 2 / span 10;
  }
}
</style>
